<div class="container no-access">
  <div fxLayout="row" fxLayoutAlign="center start">
      <mat-card class="no-access-card">
          <mat-card-content>
              <div fxFlex="100">                     
                 <h1 class="header-title">Page Not Found</h1>
                  <p class="card-title"> No page exists for this request. Please click
                  <a rel='nofollow' href="javascript:void(0);" (click)="goToDashboard()"> here </a> go to  Dashboard </p>
              </div>
          </mat-card-content>
      </mat-card>
  </div>
</div>
