import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: "app-app-loader",
  templateUrl: "./app-loader.component.html",
  styleUrls: ["./app-loader.component.scss"]
})
export class AppLoaderComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<AppLoaderComponent>) { }

  ngOnInit() {
  }

}
