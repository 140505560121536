import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppLoaderComponent } from './app-loader/app-loader.component';
import { AppLoaderService } from './app-loader/app-loader.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }
   
  
   
}
