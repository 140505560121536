import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/services/auth-guard.service';
import { NotFoundComponent } from './sessions/not-found/not-found.component';

export const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {path: 'auth' , redirectTo: 'auth', pathMatch: 'full'},
  {
    path: '',
    loadChildren: () => import('../app/shared/core/layout/layout.module').then(m => m.LayoutModule) ,
   
  },
  {
    path: 'auth',
    loadChildren: () => import('../app/sessions/sessions.module').then(m => m.SessionsModule) ,
  },
  { path: '**', component: NotFoundComponent,  },
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
  onSameUrlNavigation: 'reload',
});


