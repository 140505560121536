import { Injectable } from "@angular/core";
import { AppLoaderComponent } from "./app-loader.component";
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AppLoaderService {
  public isOpen: boolean = false;
  dialogRef: MatDialogRef<AppLoaderComponent>;

  constructor(public dialog: MatDialog, private toastr: ToastrService) { }

  public open(): Observable<boolean> {
    if (this.isOpen === false) {
      this.dialogRef = this.dialog.open(AppLoaderComponent, { disableClose: true, panelClass: "apploadercontainer" });
      this.dialogRef.updateSize("170px");
      this.isOpen = true;
      return this.dialogRef.afterClosed();
    }
  }

  public close() {
    if (this.isOpen === true) {
      this.isOpen = false;
      this.dialogRef.close();
    }
  }

  successToastr() {

  }

  errorMessage(message?) {
    this.toastr.error(message? message : 'Unable to fetch data at this moment.Please try later' , 'Failed');
  }

  successMessage(message) {
    this.toastr.success(message , 'Success');
  }

  infoMessage(message) {
    this.toastr.info(message, 'Item Details');
  }
}
