import { Component, OnInit, HostListener } from "@angular/core";
import { MediaReplayService } from "./shared/utils/media-replay.service";
import { ExternalService } from "./shared/services/external.service";
import { NgxPermissionsService } from "ngx-permissions";
import { AppLoaderService } from "./shared/services/app-loader/app-loader.service";

@Component({
  selector: "vr-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  constructor(
    public service: ExternalService  ) {}

  ngOnInit() {
    
  }

 
}
