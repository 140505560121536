import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() {
  }

  goToDashboard() {
    if (localStorage.getItem('isLawyer') === 'true') {
      this.router.navigate(['/dashboard/user_dashboard']);
    } else {
      this.router.navigate(['/properties/dashboard']);
    }
  }

}
