import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NgxPermissionsService } from 'ngx-permissions';

const helper = new JwtHelperService();


@Injectable({ providedIn: 'root' })
export class AuthGuard  {

constructor(private router: Router, private permissionsService: NgxPermissionsService) { }

canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('token')) {
        // logged in so return true
        let per = localStorage.getItem('permissions') ? JSON.parse(localStorage.getItem('permissions')) : null;
            if (per)  {
                this.permissionsService.loadPermissions(per);
            }
        return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url }});
    return false;
}
}